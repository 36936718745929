import { locale } from '../constant'

if (locale === 'zh_CN') {
  window.moment.updateLocale('en', {
    relativeTime: {
      future: '%s内',
      past: '%s前',
      s: '几秒',
      ss: '%d秒',
      m: '1分钟',
      mm: '%d分钟',
      h: '1小时',
      hh: '%d小时',
      d: '1天',
      dd: '%d天',
      M: '1个月',
      MM: '%d个月',
      y: '1年',
      yy: '%d年',
      w: '1周',
      ww: '%d周'
    }
  })
}

const formatDate = (date, formatStr, forceFormat = false) => {
  let mFormat = ''
  if (formatStr) {
    let [, b] = formatStr.split(' ')
    mFormat = !b ? '' : ` ${b}`
  }
  let defaultFormat = locale === 'zh_CN' ? 'YYYY-MM-DD' : 'MM/DD/YYYY'
  let tz = locale === 'zh_CN' ? 'Asia/Shanghai' : 'America/Chicago'
  return window
    .moment(date)
    .tz(tz)
    .format(forceFormat ? formatStr : `${defaultFormat}${mFormat}`)
}

const formatDateFromNow = (date) => {
  let tz = locale === 'zh_CN' ? 'Asia/Shanghai' : 'America/Chicago'
  return window.moment(date).tz(tz).fromNow()
}

const dateDiff = (date, type = 'days') => {
  return window.moment().diff(window.moment(date), type)
}

export { formatDate, formatDateFromNow, dateDiff }
