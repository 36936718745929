import { app_key, square_url } from '@/module/constant.js'

var loader = function (key, url) {
  if (window[key]) {
    return Promise.resolve(window[key])
  } else {
    let d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'
    g.async = true
    g.defer = true
    g.src = url
    s.parentNode.insertBefore(g, s)
    return new Promise((resolve) => {
      if (g.addEventListener) {
        g.addEventListener(
          'load',
          function () {
            resolve(window[key])
          },
          false
        )
      } else if (g.attachEvent) {
        g.attachEvent('onreadystatechange', function () {
          let target = window.event.srcElement
          if (target.readyState == 'loaded') {
            resolve(window[key])
          }
        })
      }
    })
  }
}

const CKEDITOR_loader = function () {
  // let url = 'https://cdn.ckeditor.com/4.14.1/standard-all/ckeditor.js'
  // let url = 'https://cdnjs.cloudflare.com/ajax/libs/ckeditor/4.14.1/ckeditor.js'
  let url = '/static/ckeditor/ckeditor.js'
  let key = 'CKEDITOR'
  return loader(key, url)
}

let stripeP = null
const Stripe_loader = function () {
  let url = 'https://js.stripe.com/v3/'
  let key = 'Stripe'
  if (!stripeP) {
    stripeP = loader(key, url).then((Stripe) => {
      const stripe = Stripe(app_key)
      const elements = stripe.elements({
        locale: 'US'
      })
      return Promise.resolve({ stripe, elements })
    })
  }
  return stripeP
}
const PayPal_loader = function () {
  let url = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL}&vault=true`
  let key = 'paypal'
  return loader(key, url)
}

let square = null
const Square_loader = function () {
  let url = square_url
  let key = 'Square'
  if (!square) {
    square = loader(key, url).then((Square) => {
      return Promise.resolve(Square)
    })
  }
  return square
}

const ACE_lang_loader = function () {
  let url = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/ext-language_tools.js'
  let key = 'ace_lang'
  return loader(key, url)
}

const ACE_loader = function () {
  let url = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/ace.js'
  let key = 'ace'
  let p = loader(key, url)
  return new Promise((resolve) => {
    p.then(() => {
      Promise.all([ACE_lang_loader()]).then(() => {
        resolve(p)
      })
    })
  })
}

export { CKEDITOR_loader, Stripe_loader, PayPal_loader, Square_loader, ACE_loader }
