var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "client-toolbar",
      attrs: {
        light: "",
        app: "",
        fixed: "",
        height: "60",
        "extension-height": ""
      }
    },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "d-md-none",
        staticStyle: { color: "#000" },
        on: {
          click: function($event) {
            _vm.$store.state.drawer = !_vm.$store.state.drawer
          }
        }
      }),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "right-btns", staticStyle: { display: "flex" } },
        [
          _c(
            "v-layout",
            [
              _vm.clientId && _vm.clientId !== -1
                ? _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-email mr-3",
                          staticStyle: { color: "#000" },
                          attrs: {
                            depressed: "",
                            small: "",
                            title: _vm.$t("menu.emails[1]"),
                            to: "/emails",
                            icon: ""
                          }
                        },
                        [
                          _c(
                            "v-badge",
                            {
                              staticClass: "badge-email",
                              attrs: { color: "error", overlap: "", dot: "" },
                              model: {
                                value: _vm.emailFlag,
                                callback: function($$v) {
                                  _vm.emailFlag = $$v
                                },
                                expression: "emailFlag"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticStyle: { "font-size": "20px" } },
                                [_vm._v("email")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "open-on-hover": "",
                        bottom: "",
                        "offset-y": "",
                        transition: "slide-x-transition"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  {
                                    staticClass: "d-flex align-center",
                                    staticStyle: { color: "#000" }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: { "font-size": "20px" },
                                          attrs: { color: "#000" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.clientInfo.first_name ||
                                                  _vm.clientInfo.last_name ||
                                                  _vm.clientInfo.email
                                                  ? "person outline"
                                                  : "account_box"
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.clientInfo.first_name &&
                                            _vm.clientInfo.last_name
                                            ? _vm.clientInfo.first_name +
                                                " " +
                                                _vm.clientInfo.last_name
                                            : _vm.clientInfo.email
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "grey lighten-4", attrs: { dense: "" } },
                        [
                          _vm._l(_vm.menus, function(item, i) {
                            return [
                              item.divider
                                ? _c("v-divider", { key: i })
                                : _c(
                                    "v-list-item",
                                    {
                                      key: i,
                                      attrs: {
                                        to: item.handler ? undefined : item.path
                                      },
                                      on: {
                                        click: function() {
                                          item.handler && item.handler()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        {
                                          staticStyle: { "min-width": "36px" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticStyle: { color: "#000" } },
                                            [_vm._v(_vm._s(item.icon))]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.$t(item.text)) +
                                                "\n                  "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }