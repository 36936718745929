<template>
  <div class="detail" v-if="loadReady">
    <textarea :id="RId" v-bind="$attrs"></textarea>
    <slot></slot>
  </div>
</template>

<script>
import { CKEDITOR_loader } from '@/module/service/lazyLoad'
export default {
  name: 'Editor',
  data() {
    return {
      loadReady: false,
      RId: 'edit' + Math.round(Math.random() * 10000)
    }
  },
  mounted() {
    this.ckp.then(() => {
      this.$nextTick(() => {
        this.CKEDITOR.disableAutoInline = true
        this.CKEDITOR.replace(this.RId, {
          language: 'en',
          toolbar_Basic: [
            [
              'Undo',
              'Redo',
              'Styles',
              'Format',
              'Font',
              'FontSize',
              'Bold',
              'Italic',
              'Strike',
              'RemoveFormat',
              'NumberedList',
              'BulletedList',
              '-',
              'Outdent',
              'Indent',
              'Link',
              'Unlink',
              'Table',
              'TextColor',
              'BGColor',
              'Maximize',
              'SpellChecker'
            ]
          ],
          toolbar: 'Basic',
          image_previewText: ' '
        })
      })
    })
  },
  methods: {
    getData() {
      return this.CKEDITOR.instances[this.RId].getData()
    },
    setData(content, cb) {
      this.ckp.then((CKEDITOR) => {
        this.$nextTick(() => {
          CKEDITOR.instances[this.RId].setData(content, {
            callback: function () {
              cb && cb()
            }
          })
        })
      })
    }
  },
  created() {
    this.ckp = CKEDITOR_loader()
    this.ckp.then((CKEDITOR) => {
      this.CKEDITOR = CKEDITOR
      this.loadReady = true
    })
  }
}
</script>

<style lang="scss"></style>
