// import jsrsasign from 'jsrsasign'
import $http from '@/module/http'
import { getPermission } from '@/permission'

let info = null
let countries = null
let actor = process.env.VUE_APP_ACTOR
let validatearr =
  actor === 'hwy'
    ? ['phone_number']
    : ['email', 'first_name', 'last_name', 'country', 'state', 'city', 'address1', 'postcode']
//['email', 'first_name', 'country', 'state', 'city', 'address1', 'postcode']
export default {
  strict: true,
  namespaced: true,
  state: {
    isNewClient: false,
    unpaid: 0,
    clientInfo: {
      id: ''
    }
  },
  getters: {
    checkInfoCompleted: (state) => {
      return () => {
        if (getPermission(['billing.information.validate'])) {
          let arr = Object.keys(state.clientInfo)
          let flag = true
          arr.map((key) => {
            if (validatearr.indexOf(key) !== -1 && !state.clientInfo[key]) {
              flag = false
            }
          })
          return flag
        } else {
          return true
        }
      }
    }
  },
  mutations: {
    setClientInfo(state, payload) {
      state.clientInfo = payload
    }
  },
  /**
   * @params  flag  {Boolean} whether force to refresh info
   * */
  actions: {
    getClientInfo: ({ rootState, commit }, flag) => {
      if (!flag && info) {
        return info
      }
      if (rootState.oAuth.clientId === -1) {
        let res = { id: -1 }
        commit('setClientInfo', res)
        info = Promise.resolve(res)
      } else {
        info = $http.get(`/clients/${rootState.oAuth.clientId}/`).then((res) => {
          commit('setClientInfo', res)
        })
      }
      return info
    },
    getCountries: () => {
      if (!countries) {
        countries = $http
          .get(`/countries/`, {
            params: {
              page_size: 300
            }
          })
          .then((res) => {
            return Promise.resolve(res.results)
          })
      }
      return countries
    },
    getUnPaid: ({ state, commit }) => {
      return $http
        .get('/invoices/', {
          params: {
            client_id: state.clientInfo.id,
            page_size: 999,
            q: 'status=Unpaid'
          }
        })
        .then((res) => {
          commit(
            'setState',
            {
              module: 'client',
              payload: {
                unpaid: res.total
              }
            },
            { root: true }
          )
          return Promise.resolve(res.total)
        })
    },
    getFreeTrialStatus: () => {
      return $http({
        method: 'get',
        url: `/clients/status/`
      })
    }
  }
}
