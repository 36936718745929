import cookie from '@/module/utils/cookie'
import { domain } from '@/module/constant'

let login = function () {
  window.location.href = `${domain.SSO}/login/?next=${window.location.href}`
}

let logout = function () {
  cookie.remove('token')
  window.location.href =
    `${domain.SSO}/logout/?next=` +
    encodeURIComponent(`${domain.SSO}/login/?next=${window.location.href}`)
}

let signup = function () {
  window.location.href = `${domain.SSO}/signup/?next=${window.location.href}`
}

export { login, logout, signup }
