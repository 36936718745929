<template>
  <span>
    <slot></slot>
    <i v-if="required" class="required-dot" :style="{ color: $vuetify.theme.themes.light.error }">
      *
    </i>
  </span>
</template>

<script>
export default {
  name: 'RequiredDot',
  props: {
    required: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.required-dot {
  display: inline-block;
  width: 10px;
}
</style>
