import mixpanel from 'mixpanel-browser'
import Vue from 'vue'

let token = process.env.VUE_APP_MIXPANEL
let needInit = !!token
if (needInit) {
  mixpanel.init(token)
}

let SDK = {
  install(V) {
    V.prototype.$SDK = {
      set(options) {
        needInit && mixpanel.people.set(options)
      },
      identify(id) {
        needInit && mixpanel.identify(id)
      },
      track({ pageName, productName }) {
        needInit &&
          mixpanel.track('pageView', {
            pageUrl: window.location.href,
            pageName,
            productName
          })
      },
      trackEvent(eventName = 'Click', options = { name: 'default' }) {
        if (eventName === 'Click') {
          options.clickName = options.name
          options.pageName = options.page
          delete options.name
          delete options.page
        }
        needInit &&
          mixpanel.track(
            eventName,
            Object.assign(
              {
                pageUrl: window.location.href
              },
              options
            )
          )
      }
    }
  }
}

Vue.use(SDK)
