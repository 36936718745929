import '@babel/polyfill'
import Vue from 'vue'
import Box from '@dbm/box'
import DIcon from '@/components/core/DIcon.vue'
import FyVueSkeleton from 'fy-vue-skeleton'
import './permission'
import './plugins/http'
import './plugins/filters'
import './plugins/sdk'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import i18n from './i18n'
import 'font-awesome/css/font-awesome.min.css'
import 'fy-vue-skeleton/lib/fy-vue-skeleton.css'
import '@/css/base/index.scss'
import vuetify from './plugins/vuetifyExt'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(Box)
Vue.use(FyVueSkeleton)
Vue.component(DIcon.name, DIcon)
Vue.prototype.$message.config({
  top: 120,
  duration: 5
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
