<template>
  <v-dialog v-model="dialog" persistent :max-width="760" no-click-animation scrollable>
    <v-card class="update-mod">
      <v-card-title class="mx-2" style="justify-content: center; flex-direction: column">
        <v-layout justify-center align-center wrap>
          <v-flex sm12 class="font-weight-bold text-center" style="font-size: 18px">
            {{ $t('msg_box[1]') }}
          </v-flex>
          <!--          <v-flex-->
          <!--            sm12-->
          <!--            md10-->
          <!--            lg10-->
          <!--            class="mt-2 font-weight-regular text-center"-->
          <!--            style="text-align: center; font-size: 14px">-->
          <!--            {{ $t('msg_box[2]') }}-->
          <!--          </v-flex>-->
        </v-layout>
        <span class="close-icon" v-if="canSkip">
          <v-btn depressed text icon color="dark lighten-2" @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-text class="py-0" :class="widthClasses">
        <UpdateForm v-if="dialog" ref="billingform"></UpdateForm>
      </v-card-text>
      <v-card-actions class="mx-3 my-3">
        <v-layout justify-center align-center wrap>
          <v-flex sm10 md8 class="text-center">
            <v-btn
              depressed
              small
              block
              class="text-none"
              large
              color="primary"
              :loading="loading"
              @click="updateBillingInfo">
              {{ $t('button.update') }}
            </v-btn>
          </v-flex>
          <!--          <v-flex sm12 md10 lg10 class="mt-3 text-center" v-if="canSkip && showTip">-->
          <!--            <a @click="skipHandler" style="text-decoration: underline">{{ $t('msg_box[3]') }}</a>-->
          <!--          </v-flex>-->
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import loadingMixin from '@/module/mixins/loading.mixin.js'
// import {mapGetters} from 'vuex'
import UpdateForm from './UpdateForm.vue'

export default {
  name: 'UpdateBillingInfo',
  mixins: [loadingMixin],
  i18n: {
    sharedMessages: {
      en: {
        msg_box: [
          'Your Billing Information was updated successfully',
          'Update Billing Information',
          'Update your billing profile to get an invoice and make payment.',
          'Update profile later when I decide to make payment.',
          'I will update my billing profile while I decide to pay your service.'
        ]
      },
      zh_CN: {
        msg_box: [
          '您的账户信息更新成功',
          '完善账户信息',
          '完善账户信息以便后续产生账单进行支付',
          '稍后支付时再完善账户信息',
          '稍后完善个人资料'
        ]
      }
    }
  },
  components: { UpdateForm },
  data() {
    return {
      showTip: true,
      canSkip: false,
      callback: null
    }
  },
  computed: {
    widthClasses() {
      if (['xs', 'sm'].indexOf(this.$vuetify.breakpoint.name) > -1) {
        return 'px-0'
      } else {
        //'md','lg','xl'
        return 'px-5'
      }
    }
  },
  methods: {
    async updateBillingInfo() {
      if (!this.$refs.billingform.$validate()) {
        return
      }
      this.loading = true
      const res = await this.$refs.billingform.submit()
      this.callback && (await this.callback(res))
      if (!this.callback) {
        this.$message.success(this.$t('msg_box[0]'))
      }
      this.loading = false
      this.close()
    },
    open(callback, canSkip = false, showTip = true, detail = null) {
      this.showTip = showTip
      this.canSkip = canSkip
      this.callback = callback || null
      this.dialog = true
      if (detail) {
        this.$nextTick(() => {
          Object.assign(this.$refs.billingform.formData, detail)
        })
      }
    },
    async skipHandler() {
      this.callback && (await this.callback())
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">
.update-mod {
  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
