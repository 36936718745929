<template>
  <v-navigation-drawer
    class="elevation-2 app-drawer"
    v-model="$store.state.drawer"
    :mini-variant-width="50"
    mobile-breakpoint="991"
    :mini-variant="miniDrawer"
    :width="miniDrawer ? 50 : 270"
    app>
    <div class="d-flex align-center justify-center pa-1 mt-2" style="height: 60px">
      <a
        :href="logoHref"
        :title="$t('logo_title')"
        target="_blank"
        style="color: #fff; text-decoration: none">
        <img class="img-logo" :src="miniDrawer ? logoSrc[1] : logoSrc[0]" />
      </a>
    </div>
    <div class="d-flex align-center justify-center pa-2" style="height: 60px">
      <template v-if="miniDrawer">
        <v-btn
          depressed
          small
          tile
          :title="$t('btn_add[1]')"
          to="/ordering"
          color="primary text-none"
          style="min-width: 30px; padding: 0 8px">
          <v-icon size="20">add</v-icon>
        </v-btn>
      </template>
      <tempalte v-else>
        <v-btn depressed :title="$t('btn_add[1]')" to="/ordering" color="primary text-none">
          <v-icon>add</v-icon>
          <span>{{ $t('btn_add[0]') }}</span>
        </v-btn>
      </tempalte>
    </div>
    <v-list>
      <template v-for="(menu, i) in navMenus">
        <v-list-group no-action v-if="menu.menus" :key="menu.text" v-bind="menu.bindAttrs">
          <template v-slot:prependIcon>
            <d-icon class="fs-0">{{ menu.icon }}</d-icon>
            <!--                <svg class="fd fs-0" aria-hidden="true">-->
            <!--                    <use :xlink:href="`#fd-${menu.icon}`"></use>-->
            <!--                </svg>-->
          </template>
          <template v-slot:activator>
            <v-list-item-title>{{ $t(menu.text) }}</v-list-item-title>
          </template>
          <template v-for="(item, i) in menu.menus">
            <v-list-item :to="item.path" :key="i" active-class="nav-item-active">
              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          v-else
          :key="i"
          v-bind="menu.bindAttrs"
          :title="$t(menu.title)"
          active-class="nav-item-active">
          <v-list-item-action>
            <d-icon class="fs-0">{{ menu.icon }}</d-icon>
            <!--              <svg class="fd fs-0" aria-hidden="true" >-->
            <!--                  <use :xlink:href="`#fd-${menu.icon}`"></use>-->
            <!--              </svg>-->
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t(menu.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-divider></v-divider>
      <div style="justify-content: center; align-items: center; height: 40px" class="d-flex">
        <v-btn icon @click="miniHandler">
          <v-icon>{{ miniDrawer ? 'chevron_right' : 'chevron_left' }}</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { cloudclustersURL } from '@/module/constant'
import logo from '@/assets/images/logo.png'
import hwylogo from '@/assets/images/hwy_logo.png'
import dbmlogo from '@/assets/images/dbm_logo.png'

let project = process.env.VUE_APP_ACTOR

const logoMap = {
  ccs: [logo, 'https://images.cloudclusters.io/bcbdf56eed954923abe96490146509b5/ccs-small.png'],
  hwy: [hwylogo, 'https://images.cloudclusters.io/f12211ce1ee5453586a6a38a1c5982f8/hwy-small.png'],
  dbm: [
    dbmlogo,
    'https://images.cloudclusters.io/d00c3fd4543c48a589a1301df1e082a3/databasemart-small.png'
  ]
}

export default {
  name: 'LeftDrawer',
  i18n: {
    sharedMessages: {
      en: {
        logo_title: 'Professional Cloud Hosting Provider in the USA',
        btn_add: ['Add New Product', 'Discover Popular Products']
      },
      zh_CN: {
        logo_title: '美国专业的云托管提供商',
        btn_add: ['立即订购', '立即订购新产品']
      }
    }
  },
  data() {
    return {
      miniDrawer: null
    }
  },
  watch: {
    '$vuetify.breakpoint.name': {
      handler(val) {
        if (['md'].indexOf(val) !== -1) {
          this.miniDrawer = true
        }
      },
      immediate: true
    },
    '$store.state.drawer': {
      handler(val) {
        if (val) {
          this.miniDrawer = false
        }
      }
    }
  },
  computed: {
    logoHref() {
      return cloudclustersURL
    },
    logoSrc() {
      return logoMap[this.$project]
    },
    countObj() {
      const obj = {}
      this.availablePackages.forEach((o) => {
        obj[o.product__type] = o.count
      })
      return obj
    },
    navMenus() {
      const menus = [
        {
          icon: 'home',
          text: 'menu.home[0]',
          bindAttrs: {
            to: '/'
          },
          title: 'menu.home[1]'
        },
        {
          icon: 'app',
          text: this.getMenuText('menu.apps[0]', 'APP'),
          bindAttrs: {
            to: '/applications'
          },
          title: 'menu.apps[1]',
          permission: ['menu.apps']
        },
        {
          icon: 'vps',
          text: this.getMenuText('menu.vps[0]', 'VM'),
          bindAttrs: {
            to: '/myvps'
          },
          title: 'menu.vps[1]',
          permission: ['menu.vps']
        },
        {
          icon: 'gpu',
          text: this.getMenuText('menu.gpu[0]', 'GPU'),
          bindAttrs: {
            to: '/gpu-servers'
          },
          title: 'menu.gpu[1]',
          permission: ['menu.gpu']
        },
        {
          icon: 'dedicated',
          text: this.getMenuText('menu.dedicated[0]', 'Bare Metal'),
          bindAttrs: {
            to: '/dedicated-servers'
          },
          title: 'menu.dedicated[1]',
          permission: ['menu.dedicated']
        },
        {
          icon: 'domain',
          text: this.getMenuText('menu.domain[0]', 'Domain'),
          bindAttrs: {
            to: '/domain'
          },
          title: 'menu.domain[1]',
          permission: ['menu.products.domain']
        },
        {
          icon: 'ssl',
          text: this.getMenuText('menu.ssl[0]', 'SSL'),
          bindAttrs: {
            to: '/ssl'
          },
          title: 'menu.ssl[1]',
          permission: ['menu.products.ssl']
        },
        {
          icon: 'apps',
          text: 'menu.all[0]',
          bindAttrs: {
            to: '/ordering'
          },
          title: 'menu.all[1]',
          noToken: true
        },
        {
          icon: 'billing',
          text: 'menu.billing[0]',
          bindAttrs: {
            group: '/billing'
          },
          title: 'menu.billing[1]',
          menus: [
            { text: 'menu.billing_invoices[0]', path: `/billing/invoices` },
            {
              text: 'menu.billing_information[0]',
              path: `/billing/billing-info`,
              permission: ['menu.billing.information']
            },
            {
              text: 'menu.billing_payment[0]',
              path: `/billing/paymentmethod`,
              permission: ['menu.billing.payment']
            },
            { text: 'menu.billing_credits[0]', path: `/billing/credits` }
          ]
        },
        {
          icon: 'support',
          text: 'menu.tickets[0]',
          bindAttrs: {
            to: '/ticket'
          },
          title: 'menu.tickets[1]'
        },
        {
          icon: 'friend',
          text: 'menu.affiliate[0]',
          bindAttrs: {
            to: '/affiliate'
          },
          title: 'menu.affiliate[1]',
          permission: ['menu.affiliate']
        },
        {
          icon: 'doc',
          text: 'menu.doc[0]',
          bindAttrs: (function () {
            if (project === 'hwy') {
              return {
                href: 'https://www.huwangyun.cn/blog',
                target: '_blank'
              }
            } else if (project === 'dbm') {
              return {
                href: 'https://www.databasemart.com/blog',
                target: '_blank'
              }
            } else {
              return {
                to: '/document'
              }
            }
          })(),
          title: 'menu.doc[1]',
          noToken: true
        }
      ]
      let arr = menus.filter((o) => {
        if (o.menus) {
          o.menus = o.menus.filter((k) => {
            return k.permission ? this.$p(k.permission) : true
          })
        }
        return o.permission ? this.$p(o.permission) : true
      })
      return arr.filter((o) => {
        return this.clientId === -1 || !this.clientId ? o.noToken : true
      })
    }, //
    ...mapState('oAuth', ['clientId']),
    ...mapState('application', ['availablePackages'])
  },
  methods: {
    miniHandler() {
      this.miniDrawer = !this.miniDrawer
    },
    getMenuText(text, type) {
      const count = this.countObj[type]
      return count ? `${this.$t(text)} (${count})` : `${this.$t(text)} (0)`
    }
  }
}
</script>

<style lang="scss">
.v-navigation-drawer__content {
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 0;
  }
}
.app-drawer {
  .v-list {
    .nav-item-active {
      color: var(--v-primary-base);
      &:after {
        position: absolute;
        left: 0;
        content: '';
        width: 6px;
        height: 100%;
        background-color: var(--v-primary-base);
      }
    }
    .v-list-item:hover {
      color: var(--v-primary-base);
      &:before {
        opacity: 0.12;
      }
    }
    .v-list-item:not(.v-list-group__header) {
      height: 50px;
    }
    .v-list-group .v-list-group__header .v-list-group__header__prepend-icon {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}
</style>
