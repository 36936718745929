<template>
  <div class="n-box">
    <v-text-field
      :label="label"
      :rules="rules"
      v-model="inputValue"
      class="input-box"
      :solo="solo"
      clearable
      :hide-details="!showDetails"
      hide-no-data>
      <v-select
        slot="prepend"
        :items="countries"
        v-model="singleCountry"
        item-text="name"
        item-value="iso2"
        class="pa-0 mb-0 iso2 select-box"
        :solo="solo"
        hide-details>
        <template v-slot:selection="{ item }">
          <div class="pl-2 select-item d-flex align-center">
            <span class="na-flag d-inline-block mr-1" :class="item.iso2"></span>
            <span class="grey--text text-caption">{{ `+ ${item.dialCode}` }}</span>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div class="d-flex align-center">
            <span class="na-flag mr-1 d-inline-block" :class="item.iso2"></span>
            <span>{{ item.name }}</span>
            <span class="grey--text">{{ item.dialCode ? ` (+ ${item.dialCode})` : '' }}</span>
          </div>
        </template>
      </v-select>
      <template slot="label" v-if="required">
        <RequiredDot>{{ label }}</RequiredDot>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import { allCountries } from '@/module/constant'
import RequiredDot from '@/components/RequiredDot.vue'

export default {
  props: {
    rules: [],
    solo: Boolean,
    required: Boolean,
    value: String,
    label: String,
    showDetails: Boolean
  },
  data() {
    return {
      countries: [],
      number: this.value || ''
    }
  },
  components: { RequiredDot },
  watch: {
    value(v) {
      this.number = v
    }
  },
  computed: {
    // input
    inputValue: {
      get() {
        if (!this.number) {
          return
        }
        let [g, ...n] = this.number.split(' ')
        let p = /[a-z]/i
        if (!p.test(g) || !n) {
          return g + ' ' + n.join(' ').trim()
        }
        return n.join(' ').trim()
      },
      set(val) {
        this.number = [this.singleCountry, val].join(' ')
        this.$emit('input', this.number)
      }
    },
    // select
    singleCountry: {
      get() {
        let [g] = this.number.split(' ')
        let p = /[a-z]/i
        if (p.test(g)) {
          return g
        }
        return this.$p(['account.information.bind_phone']) ? 'cn' : ''
      },
      set(val) {
        this.number = [val, this.inputValue].join(' ')
        this.$emit('input', this.number)
      }
    }
  },
  methods: {
    init() {
      this.countries = allCountries.map((c) => {
        return {
          name: c[0],
          iso2: c[1],
          dialCode: c[2]
        }
      })
    },
    findCode(code) {
      return this.countries.find((item) => item.iso2 === code)
    },
    sendData(obj) {
      this.input = obj.dialCode + ' '
    }
  },
  created() {
    this.init()
  }
}
</script>
<style lang="scss">
//@import "./index.scss";
.n-box {
  .select-item + input {
    width: 80px;
  }
  .v-input__append-outer,
  .v-input__prepend-outer {
    margin-top: -4px;
  }
  .v-text-field--solo .v-input__prepend-outer {
    margin-top: 0;
  }
  .v-select__selections {
    width: 80px;
  }
  .v-input__prepend-inner {
    margin-top: 0;
    .select-box {
      margin-top: 0px;

      &.v-input--has-state.error-text {
        border-bottom: 1px solid #ff5252;
      }
      &.error-text {
        .v-input__control .v-input__slot {
          &:before {
            border-color: #ff5252;
          }
        }
      }
    }
    .error-text {
      border-bottom: 1px solid #ff5252;
    }
  }

  .v-text-field--solo {
    .v-input__slot {
      padding-left: 5px !important;
    }
  }
  .input-box {
    .v-label--active.primary--text {
      color: #000 !important;
    }
    .v-label--active {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: -1 !important;
      }
    }
  }
}
</style>
