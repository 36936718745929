export default {
  'payment_method.credit_card': true,
  'payment_method.paypal': false,
  'payment_method.credit': false,
  'payment_method.ali_pay': true,
  'payment_method.wechat_pay': true,

  'menu.apps': false,
  'menu.vps': true,
  'menu.dedicated': true,
  'menu.ssl': false,
  'menu.domain': false,
  'menu.gpu': true,
  'menu.billing.information': true,
  'menu.billing.payment': false,
  'menu.dns': true,
  'menu.affiliate': true,
  'menu.products.app': false,
  'menu.products.vps': true,
  'menu.products.gpu': true,
  'menu.products.dedicated': true,
  'menu.products.ssl': false,
  'menu.products.domain': false,

  'account.membership': false,
  'account.information.type.simple': true,
  'account.information.bind_phone': true,

  'billing.information.validate': true,
  'billing.account.retain': false,

  'order.information.payment': false
}
