export default {
  data() {
    return {
      dialog: false,
      loading: false,
      formData: {}
    }
  },
  methods: {
    open(options) {
      if (options) {
        this.formData = Object.assign(this.formData, options)
      }
      this.dialog = true
    },
    close() {
      if (this.$listeners.close) {
        this.$listeners.close()
      }
      if (!this.loading) {
        this.dialog = false
      }
    },
    ok(cb) {
      if (this.$validator && !this.$validator()) {
        return
      }
      let done = () => {
        this.loading = false
        this.dialog = false
      }
      if (this.$listeners.confirm) {
        this.loading = true
        let p = this.$listeners.confirm(this.formData)
        if (p) {
          p.then(() => {
            done()
          }).catch((e) => {
            this.loading = false
            this.$afterError && this.$afterError(e)
          })
        } else {
          done()
        }
      } else {
        if (cb) {
          this.loading = true
          let p = cb()
          if (p && p instanceof Promise) {
            p.then(() => {
              done()
            }).catch(done())
          } else {
            done()
          }
        }
      }
    }
  }
}
