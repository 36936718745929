export default {
  strict: true,
  namespaced: true,
  state: {
    token: '',
    clientId: ''
  },
  getters: {
    judgeOAuth: (state) => {
      if (!state.clientId || (state.clientId && state.clientId === -1)) {
        return false
      } else {
        return true
      }
    }
  },
  mutations: {
    setOAuth(state, payload) {
      let keys = Object.keys(payload)
      keys.map((k) => (state[k] = payload[k]))
    }
  },
  actions: {
    // judgeOAuth:({ state }) => {
    //     if(!state.clientId || (state.clientId && state.clientId === -1)){
    //         return false
    //     }else{
    //         return true
    //     }
    // }
  }
}
