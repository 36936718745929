import $http from '@/module/http'

let pTickets = []
export default {
  strict: true,
  namespaced: true,
  state: {
    ticketList: []
  },
  getters: {
    badgeNum(state) {
      let arr = state.ticketList.filter((o) => {
        return o.status === 'Waiting' || o.status === 'Active'
      })
      return arr.length
    }
  },
  actions: {
    getTickets: ({ rootState, commit }, forceRefresh) => {
      if (!pTickets || forceRefresh) {
        pTickets = $http
          .get('/tickets/', {
            params: {
              client_id: rootState.client.clientInfo.id,
              page_size: 999
            }
          })
          .then((res) => {
            commit(
              'setState',
              {
                module: 'ticket',
                payload: {
                  ticketList: res.results
                }
              },
              { root: true }
            )
            return res.results
          })
      }
      return pTickets
    },
    sendTicket(context, payload) {
      return $http({
        method: 'post',
        url: `/tickets/`,
        data: payload
      })
    }
  }
}
