import Vue from 'vue'
import Vuetify from 'vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)
const opts = {
  // breakpoint: {
  //     thresholds: {
  //         xs: 340,
  //         sm: 540,
  //         md: 800,
  //         lg: 1280,
  //     },
  //     scrollBarWidth: 24,
  // },
  icons: {
    iconfont: 'md'
  },
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#5574E5',
        secondary: '#FFC107',
        accent: '#607D8B',
        error: '#FF5252',
        info: '#CFD8DC',
        success: '#10C610',
        warning: '#FFC107',
        'secondary-1': '#EEE',
        'secondary-2': '#F7F7F7'
      }
    }
  }
}
// Vue.use(plugin)
export default new Vuetify(opts)
