import Vue from 'vue'
import Vuex from 'vuex'
import $http from '@/module/http'

import oAuth from '@/stores/oAuth'
import client from '@/stores/client'
import application from '@/stores/application'
import affiliate from '@/stores/affiliate'
import ticket from '@/stores/ticket'
// import vps from '@/stores/vps'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    topLoading: true,
    drawer: null,
    haveOrder: ''
  },
  mutations: {
    setState(state, data) {
      let payload = data.payload
      let changeState = (state) => {
        let keys = Object.keys(payload)
        keys.map((k) => (state[k] = payload[k]))
      }
      changeState(data.module ? state[data.module] : state)
    },
    toggleLoading(state, isLoading = false) {
      if (state.topLoading === isLoading) {
        return
      }
      state.topLoading = isLoading
    }
  },
  actions: {
    getCmsInfo: (store, arr) => {
      let names = arr.join('|')
      let url = `/content-blocks/?q=name__in=${names},websites__name=Client Panel`
      return $http.get(url)
    }
  },
  modules: {
    oAuth,
    client,
    application,
    ticket,
    affiliate
  }
})
