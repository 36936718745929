<template>
  <div style="display: none" v-if="ready">
    <iframe :src="sso"></iframe>
  </div>
</template>
<script>
// import {login} from '@/module/service/oAuth'
import cookie from '@/module/utils/cookie'
import { domain } from '@/module/constant'
import { mapMutations } from 'vuex'

export default {
  name: 'Token',
  data() {
    return {
      sso: domain.SSO,
      ready: false
    }
  },
  methods: {
    getMessage({ origin, data }) {
      let { res, token, id } = data
      if (this.sso !== origin) return
      res
        ? this._saveToken({ clientId: id, token }, () => {
            this.$emit('login', true)
          })
        : this._saveToken({ clientId: -1, token: '' }, () => {
            this.$emit('login', false)
          })
    },
    _saveToken(obj, cb) {
      cookie.remove('token')
      cookie.set('token', obj.token) //, domain: window.location.origin
      this.setOAuth(obj)
      cb()
    },
    ...mapMutations('oAuth', ['setOAuth'])
  },
  mounted() {
    this.ready = true
  },
  created() {
    window.onmessage = this.getMessage
  }
}
</script>
