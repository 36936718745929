<template>
  <svg class="fd" aria-hidden="true">
    <template v-if="value">
      <use :xlink:href="`#fd-${value}`"></use>
    </template>
    <template v-else>
      <use :xlink:href="`#fd-${$slots.default[0].text}`">
        <slot></slot>
      </use>
    </template>
  </svg>
</template>

<script>
export default {
  name: 'DIcon',
  props: ['value']
}
</script>

<style scoped>
.fd {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
