<template>
  <v-form ref="form" class="update-form">
    <v-container grid-list-lg fluid>
      <v-layout wrap>
        <template v-if="$project === 'hwy'">
          <v-flex xs12 py-0>
            <div class="font-weight-bold pb-1 pl-1">
              <RequiredDot>{{ $t('user.first_name') }}</RequiredDot>
            </div>
            <v-text-field
              v-model="formData.first_name"
              :rules="[rules.required, rules.noNumOnly]"
              solo
              clearable></v-text-field>
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs6 sm6 py-0>
            <div class="font-weight-bold pb-1 pl-1">
              <RequiredDot>{{ $t('user.first_name') }}</RequiredDot>
            </div>
            <v-text-field
              v-model="formData.first_name"
              :rules="[rules.required, rules.noNumOnly]"
              solo
              clearable></v-text-field>
          </v-flex>
          <v-flex xs6 sm6 py-0>
            <div class="font-weight-bold pb-1 pl-1">
              <RequiredDot>{{ $t('user.last_name') }}</RequiredDot>
            </div>
            <v-text-field
              v-model="formData.last_name"
              :rules="[rules.required, rules.noNumOnly]"
              solo
              clearable></v-text-field>
          </v-flex>
        </template>
        <v-flex xs12 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot>{{ $t('user.country_region') }}</RequiredDot>
          </div>
          <v-autocomplete
            v-model="formData.country"
            :items="countryList"
            @change="getStates"
            item-text="name"
            :rules="[rules.required]"
            solo
            hide-no-data></v-autocomplete>
        </v-flex>
        <v-flex xs6 sm6 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot>{{ $t('user.state') }}</RequiredDot>
          </div>
          <v-autocomplete
            :disabled="disabledState || this.stateList.length === 0"
            v-model="formData.state"
            :items="stateList"
            item-text="name"
            :rules="[rules.required]"
            solo
            hide-no-data></v-autocomplete>
        </v-flex>
        <v-flex xs6 sm6 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot>{{ $t('user.city') }}</RequiredDot>
          </div>
          <v-text-field
            solo
            v-model="formData.city"
            :rules="[rules.required, rules.canNotHTMLTag]"
            clearable></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot>{{ $t('user.postcode') }}</RequiredDot>
          </div>
          <v-text-field
            v-model="formData.postcode"
            :rules="[rules.required, rules.canNotHTMLTag]"
            solo
            clearable></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot :required="false">{{ $t('user.phone_number') }}</RequiredDot>
          </div>
          <NumberInput
            solo
            v-model="formData.phone_number"
            :rules="[rules.canNotHTMLTag]"></NumberInput>
        </v-flex>
        <v-flex xs12 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot>{{ $t('user.address') }}</RequiredDot>
          </div>
          <v-text-field
            v-model="formData.address1"
            :rules="[rules.required, rules.canNotHTMLTag]"
            solo
            clearable></v-text-field>
        </v-flex>
        <v-flex xs12 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot :required="false">{{ $t('user.address_2') }}</RequiredDot>
          </div>
          <v-text-field
            v-model="formData.address2"
            :rules="[rules.canNotHTMLTag]"
            solo
            clearable></v-text-field>
        </v-flex>
        <v-flex xs12 py-0>
          <div class="font-weight-bold pb-1 pl-1">
            <RequiredDot :required="false">{{ $t('user.company') }}</RequiredDot>
          </div>
          <v-text-field
            v-model="formData.company"
            solo
            clearable
            :rules="[rules.canNotHTMLTag]"></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import rules from '@/module/rules/index.js'
import RequiredDot from '@/components/RequiredDot.vue'
import NumberInput from '@/components/NumberInput/index.vue'

export default {
  name: 'UpdateForm',
  data() {
    return {
      formData: {},
      countryList: [],
      stateList: [],
      disabledState: false,
      rules
    }
  },
  components: { RequiredDot, NumberInput },
  computed: {
    ...mapState('client', ['clientInfo'])
  },
  methods: {
    getStates(countryName) {
      let country = this.countryList.find((o) => o.name === countryName)
      if (!country) {
        this.stateList = []
      } else {
        this.disabledState = true
        this.$http
          .get('/states/', {
            params: {
              country: country.id,
              page_size: 300
            }
          })
          .then((res) => {
            this.disabledState = false
            this.stateList = res.results
          })
          .catch(() => {
            this.disabledState = false
          })
      }
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return Promise.reject()
      }
      return this.$http({
        method: 'put',
        url: `/clients/${this.clientInfo.id}/`,
        data: this.formData
      })
        .then((res) => {
          this.setClientInfo(res)
          this.$emit('submit', res)
          return Promise.resolve(res)
        })
        .catch((e) => {
          this.$message.error(e.detail)
          return Promise.reject(e)
        })
    },
    $validate() {
      return this.$refs.form.validate()
    },
    $reset() {
      this.$refs.form.reset()
    },
    init() {
      this.formData = Object.assign({}, this.clientInfo)
    },
    ...mapActions('client', ['getCountries']),
    ...mapMutations('client', ['setClientInfo'])
  },
  created() {
    this.init()
    this.getCountries().then((res) => {
      this.countryList = res
      if (this.clientInfo.country) {
        this.getStates(this.clientInfo.country)
      }
    })
  }
}
</script>

<style lang="scss">
.update-form {
  .v-input__control {
    min-height: 42px !important;
  }
  .v-input__slot {
    margin-bottom: 2px;
    box-shadow: none !important;
    border: 1px solid transparent;
    background-color: #eee !important;
    border-radius: 10px !important;
  }
  .v-text-field__details {
    margin-bottom: 0 !important;
  }
  .error--text {
    .v-input__slot {
      border: 1px solid #ff5252 !important;
    }
  }
}
</style>
