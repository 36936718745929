export default {
  'payment_method.credit_card': true,
  'payment_method.paypal': true,
  'payment_method.credit': false,
  'payment_method.ali_pay': false,
  'payment_method.wechat_pay': false,

  'menu.apps': true,
  'menu.vps': true,
  'menu.dedicated': true,
  'menu.ssl': false,
  'menu.domain': false,
  'menu.gpu': true,
  'menu.billing.information': true,
  'menu.billing.payment': true,
  'menu.affiliate': true,
  'menu.products.app': true,
  'menu.products.vps': true,
  'menu.products.gpu': true,
  'menu.products.dedicated': true,
  'menu.products.ssl': false,
  'menu.products.domain': false,

  'account.membership': true,
  'account.information.type.simple': false,
  'account.information.bind_phone': false,

  'billing.information.validate': true,
  'billing.account.retain': true,

  'order.information.payment': false
}
