<template>
  <v-app-bar light app class="client-toolbar" fixed height="60" extension-height>
    <v-app-bar-nav-icon
      class="d-md-none"
      style="color: #000"
      @click="$store.state.drawer = !$store.state.drawer"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <div style="display: flex" class="right-btns">
      <v-layout>
        <v-flex v-if="clientId && clientId !== -1">
          <v-btn
            depressed
            small
            :title="$t('menu.emails[1]')"
            to="/emails"
            icon
            style="color: #000"
            class="btn-email mr-3">
            <v-badge class="badge-email" v-model="emailFlag" color="error" overlap dot>
              <v-icon style="font-size: 20px">email</v-icon>
            </v-badge>
          </v-btn>
        </v-flex>
        <v-flex class="d-flex align-center">
          <v-menu open-on-hover bottom offset-y transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <div v-on="on" style="color: #000" class="d-flex align-center">
                <div style="width: 30px">
                  <v-icon color="#000" style="font-size: 20px">
                    {{
                      clientInfo.first_name || clientInfo.last_name || clientInfo.email
                        ? 'person outline'
                        : 'account_box'
                    }}
                  </v-icon>
                </div>
                <div>
                  {{
                    clientInfo.first_name && clientInfo.last_name
                      ? `${clientInfo.first_name} ${clientInfo.last_name}`
                      : clientInfo.email
                  }}
                </div>
              </div>
            </template>
            <v-list dense class="grey lighten-4">
              <template v-for="(item, i) in menus">
                <v-divider v-if="item.divider" :key="i"></v-divider>
                <v-list-item
                  v-else
                  :key="i"
                  :to="item.handler ? undefined : item.path"
                  @click="
                    () => {
                      item.handler && item.handler()
                    }
                  ">
                  <v-list-item-action style="min-width: 36px">
                    <v-icon style="color: #000">{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t(item.text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import { login, logout, signup } from '@/module/service/oAuth'

const menus = [
  { icon: 'panorama_fish_eye', text: 'menu.account[0]', path: '/profile/account' },
  { icon: 'keyboard', text: 'menu.password[0]', path: '/profile/password' },
  { divider: true },
  {
    icon: 'logout',
    text: 'menu.logout[0]',
    handler: () => {
      logout()
    }
  },
  {
    icon: 'exit_to_app',
    text: 'menu.login[0]',
    noToken: true,
    handler: () => {
      login()
    }
  },
  {
    icon: 'people',
    text: 'menu.sign_up[0]',
    noToken: true,
    handler: () => {
      signup()
    }
  }
]

export default {
  name: 'AppBar',
  props: ['emailFlag'],

  computed: {
    menus() {
      return menus.filter((o) => {
        return this.clientId === -1 || !this.clientId ? o.noToken : !o.noToken
      })
    },
    ...mapState('oAuth', ['clientId']),
    ...mapState('client', ['clientInfo'])
  }
}
</script>

<style scoped lang="scss">
.client-toolbar {
  box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9;
  &.theme--light.v-app-bar.v-toolbar.v-sheet {
    background: #fff;
  }
  .btn-email.v-btn--active {
    i {
      color: var(--v-primary-base);
    }
  }
}
</style>
