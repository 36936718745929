var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        persistent: "",
        "no-click-animation": ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "new-tt" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary", height: "48", fixed: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { depressed: "", icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(_vm._s(_vm.$t("button.new_ticket")))
              ])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "panel-wrapper", attrs: { "grid-list-xl": "" } },
            [
              _c(
                "div",
                { staticStyle: { "padding-top": "48px" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-form",
                            { ref: "form", staticClass: "new-ticket" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm6: "", "d-flex": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          counter: "255",
                                          rules: [
                                            _vm.$options.rules.required,
                                            function(value) {
                                              return (
                                                !value ||
                                                value.length <= 255 ||
                                                "Maximum 255 characters"
                                              )
                                            }
                                          ],
                                          clearable: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c("RequiredDot", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("subject"))
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.formData.subject,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "subject",
                                              $$v
                                            )
                                          },
                                          expression: "formData.subject"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm6: "", "d-flex": "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.departments,
                                          rules: [_vm.$options.rules.required]
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c("RequiredDot", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("department"))
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.formData.department,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "department",
                                              $$v
                                            )
                                          },
                                          expression: "formData.department"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isSalesDepartment
                                    ? [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs12: "",
                                              sm6: "",
                                              "d-flex": ""
                                            }
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.categories,
                                                "item-text": "text",
                                                "item-value": "value",
                                                clearable: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function() {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "product_category"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                2593976952
                                              ),
                                              model: {
                                                value:
                                                  _vm.formData.product_category,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "product_category",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.product_category"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    : [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs12: "",
                                              sm6: "",
                                              "d-flex": ""
                                            }
                                          },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                "item-text": "name",
                                                "item-value": "id",
                                                items: _vm.filterServices,
                                                loading: _vm.serviceLoading,
                                                disabled: _vm.itemDisabled,
                                                clearable: ""
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("service")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ]),
                                              model: {
                                                value: _vm.formData.package,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "package",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.package"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        !_vm.itemDisabled
                                          ? _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs12: "",
                                                  sm6: "",
                                                  "d-flex": ""
                                                }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    rules: [
                                                      _vm.$options.rules
                                                        .ipOrDomain
                                                    ],
                                                    clearable: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function() {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "ip_or_domain"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2683248216
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.formData.ip_or_domain,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "ip_or_domain",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.ip_or_domain"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm6: "", "d-flex": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: [_vm.$options.rules.email],
                                          clearable: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("attrs.cc"))
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.formData.cc,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "cc", $$v)
                                          },
                                          expression: "formData.cc"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "d-flex": "" } },
                                    [
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value: "tips",
                                            expression: "'tips'"
                                          }
                                        ],
                                        staticStyle: {
                                          color: "rgba(0, 0, 0, 0.54)"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                2
                              ),
                              _c("Editor", { ref: "editor" })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "Upload",
                            {
                              ref: "fileupload",
                              attrs: { size: _vm.limitSize.size },
                              scopedSlots: _vm._u([
                                {
                                  key: "files",
                                  fn: function(ref) {
                                    var files = ref.files
                                    return [
                                      _c("FileList", {
                                        attrs: { files: files },
                                        on: { remove: _vm.removeFile }
                                      })
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.fileList,
                                callback: function($$v) {
                                  _vm.fileList = $$v
                                },
                                expression: "fileList"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-0 white--text text-none",
                                  attrs: {
                                    depressed: "",
                                    dark: "",
                                    color: "blue-grey "
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.attach_files")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none mr-2",
                                  attrs: {
                                    depressed: "",
                                    color: "blue darken-1 ",
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.close()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.cancel")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none mr-2",
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.ok }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.send")) +
                                      "\n                "
                                  ),
                                  _c(
                                    "v-icon",
                                    { attrs: { right: "", dark: "" } },
                                    [_vm._v("send")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }