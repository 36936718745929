import cookie from '@/module/utils/cookie'
import axios from 'axios'
const $http = axios.create({
  baseURL: '/v1'
})
$http.interceptors.request.use(
  function (config) {
    let token = cookie.get('token')
    if (token) {
      config.headers['Authorization'] = `Token ${token}`
    }
    config.headers['content_type'] = 'application/octet-stream'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const download = function (href) {
  return $http({
    method: 'get',
    url: href,
    responseType: 'blob'
  })
    .then((res) => {
      if (!res) {
        return
      }
      // let { headers } = res
      // let [, attachment] = headers['content-disposition'].split(';')
      // let [, filename] = attachment.split('=')
      // if (filename.includes('"') && filename.split('"').length > 2) {
      //   filename = removeOuterQuotes(filename)
      // }
      let filename = 'data'
      const disposition = res.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '') // 去除引号
        }
      }
      if (window.navigator.msSaveOrOpenBlob) {
        // 兼容ie11
        let blobObject = new Blob([res.data])
        window.navigator.msSaveOrOpenBlob(blobObject, filename)
      } else {
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      }
    })
    .catch(() => {
      return Promise.reject()
    })
}

export { download }
