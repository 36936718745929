import Vue from 'vue'
let permission = {}
let project = process.env.VUE_APP_ACTOR

let getPermission = function (parr) {
  if (parr && parr.length) {
    let flag = true
    parr.forEach((key) => {
      //permission.hasOwnProperty(key)
      if (!permission[key]) {
        flag = false
      }
    })
    return flag
  }
  return false
}

function loadLocaleMessages() {
  const locales = require.context('./actors', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (locale === process.env.VUE_APP_ACTOR + process.env.NODE_ENV) {
        permission = locales(key).default
      } else if (locale === process.env.VUE_APP_ACTOR) {
        permission = locales(key).default
      }
    }
  })
  return permission
}
loadLocaleMessages()

let Permission = {
  install(V) {
    V.prototype.$permission = permission
    V.prototype.$project = project
    V.prototype.$p = getPermission
  }
}

Vue.use(Permission)

export { permission, getPermission }
