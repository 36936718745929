var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-list" }, [
    _vm.files.length
      ? _c(
          "p",
          {
            staticClass: "small-title",
            staticStyle: { "margin-bottom": "0" },
            attrs: { slot: "list" },
            slot: "list"
          },
          [_vm._v("\n    " + _vm._s(_vm.$t("title_list")) + ":\n  ")]
        )
      : _vm._e(),
    _c(
      "ul",
      _vm._l(_vm.files, function(file, index) {
        return _c(
          "li",
          { key: file.name },
          [
            _c(
              "v-layout",
              [
                _c(
                  "v-flex",
                  { attrs: { shrink: "", "d-flex": "", "align-center": "" } },
                  [
                    _c(
                      "v-layout",
                      { staticStyle: { width: "200px" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs8: "", "align-self-center": "" } },
                          [
                            _c("v-progress-linear", {
                              staticClass: "elevation-2 ma-0",
                              attrs: {
                                value: file.progress,
                                height: "5",
                                color: _vm.getStatus(file)
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs4: "", "align-self-center": "" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "padding-left": "10px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(file.progress + "%") +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { grow: "", "align-self-center": "" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "file-name",
                        class: { errormessage: _vm.getStatus(file) === "red" },
                        staticStyle: {
                          display: "inline-block",
                          "padding-left": "20px"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(file.name) + "\n          "
                        )
                      ]
                    ),
                    !file.active && !file.success
                      ? _c(
                          "span",
                          {
                            class: {
                              errormessage: _vm.getStatus(file) === "red"
                            },
                            staticStyle: {
                              display: "inline-block",
                              "padding-left": "20px"
                            }
                          },
                          [
                            _vm._v(
                              "\n            -- " +
                                _vm._s(
                                  file.error === "size"
                                    ? _vm.limitSize.errMsg
                                    : file.error
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e(),
                    file.success || file.error
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "remove-btn",
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeFile(index)
                              }
                            }
                          },
                          [_vm._v("\n            close\n          ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }