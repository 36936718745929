<template>
  <!-- <Confirm ref="cf" maxWidth="500px">
    <template v-slot:title="{ options }">
      <span>{{ $t('tip_title') }}</span>
    </template>
<template v-slot:content="{ options }">
      <p>
        <i18n path="tip_msg[0]">
            <span slot="n" @click="close()">
               <router-link  to="/profile/account" style="color: rgb(64, 157, 226)" >
                {{ $t('tip_msg[1]') }}
               </router-link>
            </span>
        </i18n>
      </p>
    </template>
<template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn depressed class="text-none" color="primary" to="/profile/account"  @click="close">{{$t('tip_msg[2]')}}</v-btn>
      </template>
</Confirm> -->
  <v-dialog v-model="dialog" persistent :max-width="500" no-click-animation scrollable>
    <v-card>
      <v-card-title class="mx-2">
        <div class="font-weight-regular" style="font-size: 24px">
          {{ showBtn ? $t('tip_mod[0]') : $t('tip_mod[1]') }}
        </div>
        <span class="close-icon" v-if="formData.canSkip">
          <v-btn depressed text icon color="dark lighten-2" @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-text>
        <v-layout v-if="showBtn">
          <v-flex xs12 px-2>
            <span>
              {{ firstStep ? $t('tip_mod[2]') : $t('tip_mod[3]', [realPhone]) }}
            </span>
          </v-flex>
        </v-layout>
        <v-form ref="form_old" v-if="!showBtn">
          <v-layout wrap>
            <v-flex xs12 md12 px-2>
              <v-text-field
                v-model="old_phone_number"
                validate-on-blur
                :rules="[rules.required, rules.cn_phone]"
                prefix="+86"
                required
                clearable>
                <template v-slot:label>
                  <RequiredDot>{{ $t('old_phone[0]') }}</RequiredDot>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
        <v-tabs-items class="mt-3" v-model="tab" v-if="showBtn">
          <v-tab-item :value="1">
            <v-form ref="form_phone">
              <v-layout wrap>
                <v-flex xs12 md12 px-2>
                  <NumberInput
                    :rules="phoneRules"
                    v-model="phone_number"
                    required
                    show-details
                    :label="showBtn ? $t('user.phone_number') : $t('old_phone[1]')"></NumberInput>
                </v-flex>
              </v-layout>
            </v-form>
          </v-tab-item>
          <v-tab-item :value="2">
            <v-form ref="form_code">
              <v-layout wrap>
                <v-flex xs8 px-2>
                  <v-text-field
                    v-model="formData.code"
                    :rules="[rules.required, codeRule]"
                    clearable
                    validate-on-blur
                    @change="codeError = true">
                    <template v-slot:label>
                      <RequiredDot>{{ $t('tip_code[0]') }}</RequiredDot>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs4 px-2 align-self-center>
                  <v-btn
                    depressed
                    v-if="!codeLoading"
                    class="text-none"
                    style="width: 92%"
                    :loading="btnLoading"
                    outlined
                    color="blue darken-1"
                    text
                    ref="codeBtn"
                    @click="fetchCode">
                    {{ $t('tip_code[1]') }}
                  </v-btn>
                  <v-btn
                    depressed
                    v-if="codeLoading"
                    class="text-none"
                    style="width: 92%"
                    outlined
                    disabled
                    text>
                    {{ `${codeTime}s` }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions class="mx-3 mb-3">
        <!--        <v-btn-->
        <!--          depressed-->
        <!--          class="text-none"-->
        <!--          outlined-->
        <!--          color="blue darken-1"-->
        <!--          text-->
        <!--          @click="skipHandler"-->
        <!--          v-if="formData.canSkip">-->
        <!--          {{ $t('button.skip') }}-->
        <!--        </v-btn>-->

        <v-spacer></v-spacer>
        <v-btn
          v-if="showBtn && firstStep"
          depressed
          class="text-none"
          size="small"
          color="primary"
          @click="handleNext">
          {{ $t('button.next') }}
        </v-btn>
        <v-btn
          depressed
          v-if="!showBtn || !firstStep"
          class="text-none"
          size="small"
          color="primary"
          :loading="loading"
          @click="ok">
          {{ $t('button.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <UpdateBillingInfo ref="updateBillingInfo"></UpdateBillingInfo>
  </v-dialog>
</template>

<script>
import loadingMixin from '@/module/mixins/loading.mixin.js'
import RequiredDot from '@/components/RequiredDot.vue'
import rules from '@/module/rules/index.js'
import { mapActions, mapState } from 'vuex'
import NumberInput from '@/components/NumberInput/index.vue'
import UpdateBillingInfo from '@/views/_components/BillingInfo/update/index.vue'

export default {
  name: 'BindPhoneConfirm',
  i18n: {
    sharedMessages: {
      en: {
        old_phone: ['Old Phone Number', 'New Phone Number'],
        tip_mod: [
          'Bind Phone Number',
          'Change Phone Number',
          'Please Enter Your Phone Number to Verify Information',
          'Verification Code Has Been Sent to Your Phone: {0}, Please Check'
        ],
        tip_code: ['Verification Code', 'Get Code', 'Invalid Verification Code Or Phone Number'],
        tip_message: ['Successfully Bound Phone Number', 'Successfully Change Phone Number'],
        skip: 'Skip'
      },
      zh_CN: {
        old_phone: ['原手机号码', '新手机号码'],
        tip_mod: [
          '绑定手机',
          '换绑手机',
          '请输入您的手机号码验证信息',
          '验证码已发送至您的手机：{0}，请注意查收'
        ],
        tip_code: ['验证码', '获取验证码', '无效的验证码或手机号'],
        tip_message: ['成功绑定手机', '换绑手机成功'],
        skip: '跳过'
      }
    }
  },
  components: { UpdateBillingInfo, NumberInput, RequiredDot },
  mixins: [loadingMixin],
  data() {
    return {
      rules,
      codeLoading: false,
      btnLoading: false,
      codeTime: 60,
      codeError: true,
      old_phone_number: '',
      phone_number: '',
      callback: null,
      tab: 1
    }
  },
  computed: {
    showBtn() {
      return !this.clientInfo.is_verified_phone
    },
    firstStep() {
      return this.tab === 1
    },
    realPhone() {
      return this.phone_number.replace('cn', '').trim()
    },
    phoneRules() {
      return this.phone_number.startsWith('cn')
        ? [rules.required, rules.cn_phone]
        : [rules.canNotHTMLTag, rules.required]
    },
    ...mapState('client', ['clientInfo'])
  },
  methods: {
    resetForm() {
      this.$refs.form_old && this.$refs.form_old.resetValidation()
      this.$refs.form_phone && this.$refs.form_phone.resetValidation()
      this.$refs.form_code && this.$refs.form_code.resetValidation()
      this.btnLoading = false
      this.codeError = true
    },
    codeRule() {
      return this.codeError || this.$t('tip_code[2]')
    },
    open(callback, canSkip = false) {
      this.formData = Object.assign({
        code: '',
        canSkip: canSkip
      })
      this.callback = callback || null
      this.old_phone_number = ''
      this.phone_number = ''
      this.resetForm()
      this.tab = 1
      this.dialog = true
    },
    skipHandler() {
      this.callback && this.callback()
      this.dialog = false
    },
    fetchCode() {
      this.btnLoading = true
      this.$http
        .put(`/clients/${this.clientInfo.id}/send_phone_verification_code/`, {
          phone_code: 'cn',
          phone_number: this.realPhone
        })
        .then(() => {
          this.btnLoading = false
          this.codeTime = 60
          this.codeLoading = true
          this.countDown()
        })
        .catch((e) => {
          this.btnLoading = false
          this.$message.error(e.detail)
        })
    },
    countDown() {
      setTimeout(() => {
        if (this.codeTime > 0) {
          this.codeTime -= 1
          this.countDown()
        } else {
          this.codeLoading = false
        }
      }, 1000)
    },
    bindNumber() {
      return this.$http
        .put(`/clients/${this.clientInfo.id}/bind_phone_number/`, {
          phone_code: 'cn',
          phone_number: this.realPhone,
          verification_code: this.formData.code
        })
        .then(({ return_code }) => {
          if (return_code === 0) {
            this.getClientInfo(true)
            this.$message.success(this.$t('tip_message[0]'))
            return Promise.resolve()
          } else {
            this.codeError = false
            this.$refs.form_code.validate()
            return Promise.reject()
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.detail)
          return Promise.reject(e.detail)
        })
    },
    changeNumber() {
      return this.$http
        .put(`/clients/${this.clientInfo.id}/change_phone_number/`, {
          phone_code: 'cn',
          old_phone_number: this.old_phone_number,
          phone_number: this.realPhone,
          verification_code: this.formData.code
        })
        .then(({ return_code }) => {
          if (return_code === 0) {
            this.getClientInfo(true)
            this.$message.success(this.$t('tip_message[1]'))
            return Promise.resolve()
          } else {
            this.codeError = false
            this.$refs.form_code.validate()
            return Promise.reject()
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.detail)
          return Promise.reject(e.detail)
        })
    },
    ok() {
      if (
        (this.showBtn || this.$refs.form_old.validate()) &&
        this.$refs.form_phone.validate() &&
        this.$refs.form_code.validate()
      ) {
        let done = () => {
          this.loading = false
          this.dialog = false
          this.callback && this.callback()
        }
        this.loading = true
        this[this.showBtn ? 'bindNumber' : 'changeNumber']()
          .then(() => {
            done()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    handleNext() {
      this.$nextTick(() => {
        if (this.$refs.form_phone.validate()) {
          if (this.phone_number.startsWith('cn')) {
            this.tab = 2
            if (!this.btnLoading) this.fetchCode()
          } else {
            this.$refs.updateBillingInfo.open(this.callback, true, true, {
              phone_number: this.phone_number
            })
            this.close()
          }
        }
      })
    },
    ...mapActions('client', ['getClientInfo'])
  }
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
