<template>
  <div v-cloak>
    <v-app light class="main-page" style="background-color: #f7f7f7">
      <LeftDrawer></LeftDrawer>
      <AppBar :emailFlag="emailFlag"></AppBar>
      <v-main id="main">
        <template v-if="ready">
          <!-- <transition
                                                appear
                                                appear-active-class="animated slideInDown"
                                                enter-active-class="animated slideInDown">
                                            <IndexNotice ></IndexNotice>
                                        </transition> -->
          <transition name="slide" mode="out-in">
            <router-view></router-view>
          </transition>
          <!--          <Feedback v-if="clientId && clientId !== -1"></Feedback>-->

          <template v-if="$p(['account.information.bind_phone'])">
            <BindPhoneConfirm
              @hook:mounted="$root.billingCpn = $refs.updatephone"
              ref="updatephone"></BindPhoneConfirm>
          </template>
          <template v-else>
            <UpdateBillingInfo
              @hook:mounted="$root.billingCpn = $refs.updatebillinginfo"
              ref="updatebillinginfo"></UpdateBillingInfo>
          </template>
          <NewTicket v-if="clientId && clientId !== -1" ref="openTicket"></NewTicket>
        </template>
        <v-overlay absolute opacity="0.1" :value="$store.state.topLoading">
          <img :src="per" />
        </v-overlay>
      </v-main>
      <!--            <v-dialog style="box-shadow: none;" :value="$store.state.topLoading" attach="#main" width="80"-->
      <!--                no-click-animation content-class="main-loading" persistent>-->
      <!--                <v-card style="background: transparent;">-->
      <!--                    <img :src="per" />-->
      <!--                </v-card>-->
      <!--            </v-dialog>-->
    </v-app>
    <Token @login="loginHook"></Token>
  </div>
</template>
<script>
import per from './assets/images/preloader.svg'
import Token from '@/components/Token.vue'
import UpdateBillingInfo from '@/views/_components/BillingInfo/update/index.vue'
import BindPhoneConfirm from '@/views/_components/BindPhoneConfirm'
import LeftDrawer from './components/core/LeftDrawer.vue'
import AppBar from './components/core/AppBar.vue'
import { mapActions, mapState } from 'vuex'
import NewTicket from '@/components/NewTicket.vue'
import { cloudclustersURL } from '@/module/constant'

const actor = process.env.VUE_APP_ACTOR
// const Feedback = () => import('@/components/feedback/index.vue')

const titleMap = {
  ccs: 'Easy Client Panel for Cloud Clusters',
  hwy: '鹄望云控制面板',
  dbm: 'Easy Client Panel for Database Mart'
}

export default {
  name: 'App',
  components: {
    Token,
    LeftDrawer,
    AppBar,
    NewTicket,
    UpdateBillingInfo,
    BindPhoneConfirm
  },
  metaInfo: {
    titleTemplate: function () {
      let match = this.$route.matched[0]
      if (match && match.meta.title) {
        return `${titleMap[actor]} - ${this.$t(match.meta.title)}`
      } else {
        return `${titleMap[actor]}`
      }
    }
  },
  data() {
    return {
      emailFlag: false,
      ready: false,
      hasLogin: false
    }
  },
  computed: {
    logoHref() {
      return cloudclustersURL
    },
    ...mapState('oAuth', ['clientId']),
    ...mapState('client', ['clientInfo'])
  },
  methods: {
    getEmail() {
      this.$http
        .get('/emails/', {
          params: { page_size: 20 }
        })
        .then(({ assist_data }) => {
          this.emailFlag = assist_data.unread > 0
        })
        .catch((e) => {
          this.$message.error(e.detail)
        })
    },
    loginHook(flag) {
      this.hasLogin = flag
      Promise.all([this.getClientInfo(), this.getProducts()]).then(() => {
        this.clientInfo.id !== -1 && this.getAvailablePackages()
        if (flag) {
          this.$nextTick(() => {
            this.$SDK.set({
              name: 'Customer',
              country: this.clientInfo.country
            })
            this.$SDK.identify(this.clientInfo.id)
          })
        }
        this.$store.commit('toggleLoading', false)
        this.ready = true
      })
      flag && this.getEmail()
    },
    ...mapActions('client', ['getClientInfo']),
    ...mapActions('application', ['getProducts', 'getAvailablePackages'])
  },
  created() {
    this.per = per
    document.getElementById('initLoading').style.display = 'none'
  },
  mounted() {
    this.$root.$on('closeEmailFlag', (flag) => {
      this.emailFlag = flag
    })
    this.$root.$on('newTicket', (payload) => {
      this.$refs.openTicket.open(payload)
    })
  }
}
</script>

<style lang="scss">
$body-font-family: Montserrat, 'Roboto', sans-serif !important;
.main-page {
  .main-loading {
    box-shadow: none;
  }
}
</style>
