import Vue from 'vue'
import { formatDate } from '@/module/utils/date'
import { url, insert, http } from '@/module/directive/index'

let plugin = {
  install(Vue) {
    Vue.filter('formatDate', formatDate)
    Vue.directive('url', url)
    Vue.directive('insert', insert)
    Vue.directive('http', http)
  }
}

Vue.use(plugin)
