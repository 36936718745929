import $http from '@/module/http'

let affp = null

export default {
  strict: true,
  namespaced: true,
  state: {
    aff: {
      id: ''
    }
  },
  getters: {},
  mutations: {
    setAff(state, payload) {
      state.aff = payload
    }
  },
  actions: {
    getAff: ({ commit }, flag) => {
      if (!flag && affp) {
        return affp
      }
      affp = $http.get(`/affiliates/`).then(({ results }) => {
        commit('setAff', results.length ? results[0] : {})
      })
      return affp
    }
  }
}
